/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

/*************************************************************************/
/* USAGE NOTES : Add font all licenses to LICENSE.text                   */
/*************************************************************************/

/******************************* Inter UI ********************************/
@import '~@fontsource/inter/200.css';
@import '~@fontsource/inter/400.css';
@import '~@fontsource/inter/500.css';
@import '~@fontsource/inter/600.css';

/******************************* Fira Code ********************************/
@import '~fontsource-fira-code/400.css';
@import '~fontsource-fira-code/500.css';
@import '~fontsource-fira-code/600.css';

/* Open Sans-400-regular*/
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../../fonts/OpenSans-Regular.ttf') format('ttf');
}

/* Open Sans-500-medium*/
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('../../fonts/OpenSans-Medium.ttf') format('ttf');
}

/* Open Sans-600-semibold*/
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('../../fonts/OpenSans-SemiBold.ttf') format('ttf');
}
